/* This example requires Tailwind CSS v2.0+ */
// import { Fragment } from "react";
import { Popover } from "@headlessui/react";
// import { HomeIcon, HeartIcon, DownloadIcon, MenuIcon, XIcon } from "@heroicons/react/outline";

// const solutions = [
//   {
//     name: "Home",
//     href: "/",
//     icon: HomeIcon,
//   },
//   {
//     name: "Features",
//     href: "/",
//     icon: HeartIcon,
//   },
//   {
//     name: "Download",
//     href: "/",
//     icon: DownloadIcon,
//   },
// ];

export default function Header() {
  return (
    <div className="bg-red-600 relative main">
      <Popover className="relative">
        {({ open }) => (
          <>
            <div className="max-w-5xl mx-auto px-4 sm:px-6">
              <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
                <div className="flex justify-start lg:w-0 lg:flex-1">
                  <a href="/">
                    <span className="sr-only">Workflow</span>
                    <img
                      className="h-14 w-auto sm:h-14"
                      src="/logo_white.png"
                      alt=""
                    />
                  </a>
                </div>
                {/* <div className="-mr-2 -my-2 md:hidden">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div> */}
                {/* <Popover.Group as="nav" className="hidden md:flex space-x-10">
                  <a
                    href="/"
                    className="text-white font-medium hover:text-gray-300"
                  >
                    Home
                  </a>
                  <a
                    href="/"
                    className="text-white font-medium hover:text-gray-300"
                  >
                    Features
                  </a>
                </Popover.Group>
                 */}
                <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                  <a
                    href="#download-button"
                    className="animate-pulse ml-8 whitespace-nowrap inline-flex items-center justify-center px-8 py-2 border 
                  border-transparent rounded-full shadow-sm text-base font-medium text-white bg-gray-900"
                  >
                    Download
                  </a>
                </div>
              </div>
            </div>

            {/* <Transition
              show={open}
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="absolute top-0 inset-x-0 p-2 z-999 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                  <div className="z-90 pt-5 pb-6 px-5">
                    <div className="flex items-center justify-between">
                      <div>
                        <img
                          className="h-14 w-auto"
                          src="./logo_red.png"
                          alt="Workflow"
                        />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:gray-900">
                          <span className="sr-only">Close menu</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="mt-6">
                      <nav className="grid gap-y-8">
                        {solutions.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                          >
                            <item.icon
                              className="flex-shrink-0 h-6 w-6 text-gray-600"
                              aria-hidden="true"
                            />
                            <span className="ml-3 text-base font-medium text-gray-900">
                              {item.name}
                            </span>
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
           */}
          </>
        )}
      </Popover>
    </div>
  );
}
